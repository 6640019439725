/* eslint react/prop-types: 0 */
import React from "react";
import {graphql} from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PostCard from "../components/post-card";
import Logo from "../images/anfmr-icon.png";

export default function IndexPage(
  {
    data: {
      allMarkdownRemark: { edges }
    }
  }
) {

  const Posts = edges.map(edge => {
    const post = edge.node;

    return (
      <div key = {post.id}>
        <PostCard title = {post.frontmatter.title} date = {post.frontmatter.date} excerpt = {post.excerpt} slug = {post.frontmatter.slug} tag = {post.frontmatter.tag} path={post.frontmatter.path}/>
        <br/>
      </div> 
    )
});

  return (
    <Layout>
      <SEO title="Home"/>
      <section className="text-center">
        <img
          alt="ANFMR Logo"
          className="block w-1/4 mx-auto mb-8"
          src={Logo}
        />
      </section>
      <section>
        <div className = "font-montserrat text-4xl text-red-dark">About us</div>
        <br />
        <div>
        <p className = "font-lora text-justify">
        <b>RURAL WOMEN NATIONAL ASSOCIATION</b>, ROMANIA, is a non profit, non-governmental
        organization, founded in 1997. It has 15 branches all over Romania. Our members represent a rich range
        of social levels: from business women to housekeepers or unemployed; from young girls to old women;
        from illiterate ones to university graduates, living and working in rural areas - from the most developed
        to isolated ones.
        </p>
        </div>
        <div>
        <p className = "font-lora text-justify">
        The mission of <b>RWNA</b> is to ensure equal chances with other social categories and to reach a real social
        protection for its members. The association encourages initiatives and entrepreneurship in valorizing
        local heritage.
        </p>
        </div>
        <div className = "font-lora text-justify">
          <div className = "font-bold">AIMS</div>
          <ul className = "list-inside list-disc pl-4">
            <li>to encourage rural women's initiatives and entrepreneurship</li>
            <li>to support women professional integration and their economical/social independence</li>
            <li>to promote local and national traditions</li>
          </ul>
        </div>
      </section>
      <br/>
      <section>
        <h1 className = "font-montserrat text-4xl text-red-dark">Latest News</h1>
        <br/>
        {Posts}
      </section>
    </Layout>
  );
}


export const query = graphql`
{
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 3) {
    edges {
      node {
        excerpt(pruneLength: 250)
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          slug
          tag
          title
          path
        }
      }
    }
  }
}
`;
